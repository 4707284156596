@import url('./global.css');

:root {
  --color-black: #000;
  --color-white: #fff;
  --color-text: var(--color-white);
  --color-alt: #ccc;
  --color-bg: #212121;
  --color-link: #fff;
  --color-info: #000;
  --color-textlink: #c3ef2b;
  --padding-base: 20px;
}

@media (min-width: 768px) {
  :root {
    --padding-base: 30px;
  }
}

@media (min-width: 1024px) {
  :root {
    --padding-base: 40px;
  }
}

body {
  font-family: Futura, 'futura-pt', sans-serif;
  min-height: 100vh;
  color: var(--color-text);
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Page Loader */
.js .loading::before {
  content: '';
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  content: '';
  position: fixed;
  z-index: 100000;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  pointer-events: none;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
}

a:hover,
a:focus {
  color: var(--color-link-hover);
}

.header {
  display: flex;
  justify-content: space-between;
  padding: var(--padding-base);
}

.title {
  position: relative;
  margin: 0;
  font-size: 1.15rem;
  z-index: 1000;
}

.title a {
  display: flex;
  align-items: center;
}

.title a i.logo svg {
  width: 3.4ch;
  height: 3.4ch;
}

.title a i.logo svg polygon {
  fill: var(--link-text);
  transition: 0.4s all;
}

.title a:hover i.logo svg .Logo > g:first-child polygon {
  transform: scale(2);
}

.title a:hover i.logo svg .Logo > g:last-child polygon {
  fill: var(--color-bg);
  transform: scale(0.55) translate(180px, 140px);
}

@media (min-width: 768px) {
  .title a i.logo svg {
    height: 4ch;
    width: 4ch;
  }
}

.title a:hover {
  color: var(--color-textlink);
}

/* Gallery page - manu */
.menu {
  position: relative;
  text-transform: lowercase;
  font-size: 1.15rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  z-index: 1000;
  max-width: 13rem;
  grid-area: menu;
}

.menu__item {
  position: relative;
  margin-right: 1em;
}

.menu__item::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 3px;
  background: var(--color-textlink);
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
  transform-origin: 0 0;
}

.menu__item:hover::after,
.menu__item--current::after {
  transform: scale3d(1, 1, 1);
}

.content {
  position: relative;
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 1400px;
  padding: 0 var(--padding-base);
  margin: auto;
}

@media (min-width: 768px) {
  .content {
    width: 80%;
  }
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content--current {
  overflow: visible;
}

.js .content {
  pointer-events: none;
}

.content--photography {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content--photography .content--category {
  margin-left: calc(10% - var(--padding-base));
  padding-right: var(--padding-base);
  flex: 1;
}

.content--photography .content--grid {
  margin-right: calc(10% - var(--padding-base));
  flex: 3;
}

@media (max-width: 767px) {
  .content--photography {
    flex-direction: column;
  }
  .content--photography .content--category {
    margin-left: 0;
  }
}

.js .content--current {
  pointer-events: auto;
  opacity: 1;
}

.content__paragraph {
  font-size: 1.15rem;
}

.content__paragraph a {
  color: var(--color-textlink);
  font-weight: bold;
}

.social__title {
  display: block;
  font-weight: bold;
}

.social__link {
  color: var(--color-alt);
  margin-bottom: 1rem;
  display: inline-block;
}

.social__link:hover {
  color: var(--color-textlink);
}

.grid {
  position: relative;
  --gap: 3vw;
  width: 100%;
  display: grid;
  grid-gap: var(--gap);
  grid-auto-rows: calc((1700px / 20) - var(--gap));
}

.grid__item {
  position: relative;
  display: flex;
  flex-direction: column;
}

.grid__item-wrap {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.js .grid__item-wrap {
  opacity: 0;
}

.grid__item-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  transition: transform 1s cubic-bezier(0.2, 1, 0.8, 1);
}

.grid__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid__item-wrap:hover .grid__item-img {
  transform: scale3d(1.1, 1.1, 1);
}

.grid__item-title {
  margin: 0;
  padding: 0.5rem 0 0 0;
  font-size: 1rem;
  color: #aaa;
  font-weight: 400;
  z-index: 999;
}

.js .grid__item-title {
  opacity: 0;
}

.category {
  position: relative;
}

.js .category {
  opacity: 0;
}

.category__list {
  margin: 0;
  padding: 0 0 20px;
  list-style: none;
}

.category__list-item {
  display: inline-block;
  text-transform: uppercase;
  margin: 0.5rem 0.5rem 0.5rem 0;
  /* color: var(--color-textlink); */
  cursor: default;
}

.category__list-item a {
  color: var(--color-textlink);
}

.category__list-item a:hover {
  color: var(--color-text);
}

.category__list-item--current {
  font-weight: bold;
  color: var(--color-text);
}

.category__list-item--current a {
  color: var(--color-text);
}

.footer {
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
}

.footer__left {
  color: var(--color-alt);
  order: 1;
  background-color: var(--color-black);
  padding: var(--padding-base) var(--padding-base) calc(2 * var(--padding-base));
}

.footer__right {
  color: #fff;
  background-color: var(--color-black);
  padding: calc(2 * var(--padding-base)) var(--padding-base) var(--padding-base);
}

.footer__right a {
  color: #fff;
}

.footer__right a {
  color: var(--color-textlink);
}

.footer__right a:hover {
  color: var(--color-text);
}

.footer__column:not(:last-child) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .footer__column.socials {
    margin-bottom: 0;
  }
}

.footer__column a:not(:last-child) {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .footer__column a:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 768px) {
  .content__paragraph {
    font-size: 2rem;
  }
  .category__list-item {
    display: block;
    margin: 0.5rem 0;
  }
  .grid {
    width: 63vw;
    grid-auto-rows: calc((1700px / 20) - var(--gap));
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .footer__left {
    flex: 1;
    margin-left: 10%;
    padding: 0;
    order: 0;
    background-color: transparent;
  }
  .footer__right {
    flex: 3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
    padding: calc(var(--padding-base) * 1.5);
  }
  .footer__column {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .footer__right {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
